import { Injectable } from '@angular/core';
import { UtilService } from 'fx-core';
import { of } from 'rxjs';
import { studentDetails, studentFees, feePaymentHistory } from './fees.data';

@Injectable()
export class FeesService {
    constructor(public utl: UtilService) {

    }

    getStudentDetails(inputData) {
        var options: any = {
            action: 'student/student/getStudentBySearch',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getStudentDropDownDetails(inputData) {
        var options: any = {
            action: 'student/student/getStudents',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    refreshStudentFee(inputData) {
        var options: any = {
            action: 'student/studentFee/refreshStudentFees',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    generateBill(inputData) {
        var options: any = {
            action: 'student/studentFee/generateBill',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getAllInsByOrg(inputData) {
        var options: any = {
            action: 'admin/institution/getInstitutions',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    cancelBill(inputData) {
        var options: any = {
            action: 'student/studentFee/cancelBill',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    cancelPayment(inputData) {
        var options: any = {
            action: 'student/studentFee/cancelPayment',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    printReceipt(inputData) {
        var options: any = {
            action: 'student/studentfee/getReceiptPdf',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doOpen(options);
    }

    paymentUpdate(inputData) {
        var options: any = {
            action: 'student/studentFee/managePayments',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getStudentFees(inputData) {
        var options: any = {
            action: 'student/studentFee/getStudentFeesForFeeCollect',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    deleteStudentFeeDetail(inputData) {
        var options: any = {
            action: 'student/studentfee/deleteStudentFeeDetail',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    addStudentFeeDetail(inputData) {
        var options: any = {
            action: 'student/studentfee/addStudentFeeDetail',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getStudentFeeDetails(inputData) {
        var options: any = {
            action: 'student/studentFeeDetail/getStudentFeeDetails',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getBills(inputData) {
        var options: any = {
            action: 'student/studentFee/getBills',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getFeePaymentHistory(inputData) {
        var options: any = {
            action: 'student/feePaymentHistory/getFeePaymentHistorys',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    getFeePaymentHistoryPdf(inputData) {
        var options: any = {
            action: 'student/feePaymentHistory/getReceiptPdf',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doOpen(options);
    }

    getRevenueReportByCourse(inputData) {
        var options: any = {
            action: 'admin/report/reportfeebyservice',
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }

    postRequest(inputData, url: string) {
        var options: any = {
            action: url,
            type: 'post',
            data: inputData
        };

        return this.utl.http.doAction(options);
    }
}